.calendar {
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
    width: 100%;
}

.month {
    font-weight: bold;
    color: black;
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
}

.calendar-table {
    width: 100%;
    border-collapse: collapse;
}

.title-row {
    background-color: yellow;
}

.day {
    text-align: center;
    padding: 8px;
    border-right: 1px solid black;
}

.date-row {
    border-bottom: 1px solid black;
}

.date-cell {
    padding: 8px;
    border-right: 1px solid black;
}

.day-number {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    text-align: center;
    line-height: 24px;
    background-color: transparent;
    color: black;
}

.green-dot {
    background-color: green;
}

.red-dot {
    background-color: red;
}
